import hyphenDE from 'hyphen/de'
import hyphenEN from 'hyphen/en'
import hyphenFR from 'hyphen/fr'
import hyphenNL from 'hyphen/nl'

export default (text: string | undefined) => {
  if (!text)
    return ''
  const { locale } = useI18n()

  switch (locale.value) {
    case 'en':
      return hyphenEN.hyphenateSync(text)
    case 'de':
      return hyphenDE.hyphenateSync(text)
    case 'fr':
      return hyphenFR.hyphenateSync(text)
    case 'nl':
      return hyphenNL.hyphenateSync(text)
    default:
      // eslint-disable-next-line no-console
      console.log(`No hyphenation for locale ${locale.value}`)
      return text // fallback to no hyphenation
  }
}
