<script setup lang="ts">
const props = defineProps<{
  link: string
  text: string
}>()
</script>

<template>
  <NuxtLinkLocale
    :to="props.link"
    class="inline-block border border-neutral-white bg-transparent px-2.5rem py-1rem text-center text-1.3rem text-neutral-white tracking-4px uppercase transition duration-300 hover:bg-neutral-white hover:text-starline-secondary"
  >
    {{ props.text }}
  </NuxtLinkLocale>
</template>

<style scoped>

</style>
