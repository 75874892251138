<script setup lang="ts">
const props = defineProps<{
  data: {
    id: string
    translations: DirectusTranslation<{
      title: string
      text: string
      button_1_text?: string
      button_1_url?: string
      button_2_text?: string
      button_2_url?: string
    }>
  }
}>()

const data = computed(() => {
  return dt(props.data.translations)
})
</script>

<template>
  <BlockBase class="relative border-t border-neutral-darkGray bg-starline-secondary py-8rem lg:py-20rem">
    <div class="absolute inset-0">
      <img class="h-full w-full object-cover" src="/images/home/home-bottom-cta-bg.jpg">
    </div>
    <!-- Gradients -->
    <div class="absolute inset-0">
      <div class="gradient-top-to-bottom absolute left-0 top-0 h-30% w-full" />
      <div class="gradient-bottom-to-top absolute bottom-0 left-0 h-30% w-full" />
      <div class="gradient-left-to-right absolute left-0 top-0 h-full w-10%" />
      <div class="gradient-right-to-left absolute right-0 top-0 h-full w-10%" />
      <div class="absolute left-0 top-0 h-full w-full bg-starline-secondary/75" />
    </div>
    <Container class="relative">
      <div class="flex flex-col items-center justify-center">
        <!-- <pre>{{ props.data }}</pre> -->
        <h1 class="mb-3.5rem text-center textH1">
          {{ hyphen(data?.title) }}
        </h1>
        <p class="mb-3.5rem max-w-47rem text-center textNormal">
          {{ data?.text }}
        </p>
        <div class="flex flex-col gap-1rem md:flex-row">
          <BtnPrimary v-if="data?.button_1_text && data.button_1_url" :link="data.button_1_url" :text="data.button_1_text" />
          <BtnSecondary v-if="data?.button_2_text && data.button_2_url" :link="data.button_2_url" :text="data.button_2_text" />
        </div>
      </div>
    </Container>
    <!-- Background -->
  </BlockBase>
</template>

<style scoped>
.gradient-left-to-right {
  background: linear-gradient(to right, #000b1a 10%, transparent 100%);
}

.gradient-right-to-left {
  background: linear-gradient(to left, #000b1a 10%, transparent 100%);
}

.gradient-top-to-bottom {
  background: linear-gradient(to bottom, #000b1a 10%, transparent 100%);
}

.gradient-bottom-to-top {
  background: linear-gradient(to top, #000b1a 10%, transparent 100%);
}
</style>
